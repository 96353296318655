@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

 * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
 }

 body {
  background: rgb(239, 239, 239);
 }

 h1,h3,h4 {
  color: #000000;
  text-decoration: none;
 }

 ul {
  list-style-type: none;
 }
